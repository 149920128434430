import { config } from '../config.js';

// TODO:
// - move hardcoded ids to .env file
const msalConfiguration = {
    auth: {
        clientId: config.MSAL_CLIENT_ID,
        authority: config.MSAL_AUTHORITY,
        redirectUri: '/',
        postLogoutRedirectUri: config.REACT_APP_ENC_LANDING_APP_URL,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
    system: {
        allowRedirectInIframe: true,
    },
};
const loginRequest = {
    scopes: [
        config.MSAL_SCOPE,
    ],
    state: window.location.pathname + window.location.search // Save current path
};

export { loginRequest, msalConfiguration };
