import { jsxs, jsx } from 'react/jsx-runtime';
import { MemoizedItemPanel } from '../ItemPanel.js';
import BreadcrumbsWithMenu from '../BreadcrumvsWithMenuProps.js';
import '@mui/material';
import { CloseButton } from '../button/CustomButton.js';
import { useAppSelector, useAppDispatch } from '../../store/store.js';
import 'react-redux';
import 'redux-persist/integration/react';
import '../../store/slices/applicationSlice.js';
import '../../store/slices/downloadFileSlice.js';
import { setCurrentItem } from '../../store/slices/itemModalSlice.js';
import { useParams, useSearchParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Box } from '../Box.js';
import { palette } from '../../themes/light.theme.js';

const ItemPanelPage = ({ isIMC = true }) => {
    const levels = useAppSelector((state) => state.itemModal.navigation.levels);
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const [queryParams] = useSearchParams();
    const [selectedTab, setSelectedTab] = useState('summary');
    useEffect(() => {
        const _selectedTab = queryParams.get('tab');
        if (_selectedTab !== selectedTab)
            setSelectedTab(_selectedTab);
    }, [queryParams]);
    const [itemId, setItemId] = useState(id);
    const handleAction = (crumb) => {
        const newCrumb = {
            ...crumb,
            id: crumb?.id ?? '',
            itemId: crumb?.itemId ?? '',
            name: crumb?.name ?? '',
        };
        setItemId(crumb?.itemId);
        if (newCrumb)
            dispatch(setCurrentItem(newCrumb));
    };
    const handleClose = () => {
        window.parent.postMessage({ type: 'closeDetailsPage' }, '*');
    };
    return (jsxs("div", { style: { display: 'flex', flexDirection: 'column', width: '100%' }, children: [jsxs(Box, { background: 'none', style: {
                    flexShrink: 0,
                    flexGrow: 0,
                    padding: '0.25rem 1.25rem 0.25rem 1.5rem',
                    borderBottom: '0.063rem',
                    borderColor: palette.bley[400],
                }, padding: 'small', direction: 'row', alignItems: 'center', children: [jsx(BreadcrumbsWithMenu, { crumbs: levels, color: '#0A0B33CC', onClick: handleAction }), jsx(Box, { direction: 'row', background: 'none', alignItems: 'center', justifyContent: 'end', height: '3rem', width: '100%', style: { flex: '1 1 auto' }, children: isIMC && (jsx(CloseButton, { sx: { color: '#0A0B33CC' }, onClick: handleClose })) })] }), jsx(MemoizedItemPanel, { initialId: id, id: itemId, initialTab: selectedTab, clearLevels: true, setId: setItemId })] }));
};

export { ItemPanelPage };
