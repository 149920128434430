import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import { DataUtil } from '@syncfusion/ej2-data';
import { styled } from '@mui/material';
import { GridComponent, Inject, Filter, Page, Resize, Sort, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-grids';
import { useRef, useState, useEffect, useMemo } from 'react';
import { Box } from '../Box.js';
import { debounce } from 'lodash';
import useSnapshotsData from './useSnapshotsData.js';
import './templates/CommentTemplate.js';
import './templates/ItemSummaryTemplate.js';
import { PagerTemplate } from './templates/PagerTemplate.js';
import '@fluentui/react-icons';
import '../../types/requestTypes.js';
import '../../types/itemTypes.js';
import './templates/RetentionClassTemplate.js';
import './templates/SummaryTemplate.js';
import './templates/DisposalStatusTemplate.js';
import { Chip } from '../chip/Chip.js';
import '../../themes/light.theme.js';
import '../chip/ItemManagementStatusChip.js';
import '../chip/PerspectiveClassChip.js';
import '../../config.js';
import '../../auth/config.js';
import '../../auth/AuthProvider.js';
import '@azure/msal-react';
import 'dayjs';
import 'react-redux';
import '@testing-library/react';
import '../../store/store.js';
import 'redux-persist/integration/react';
import '../../store/slices/applicationSlice.js';
import '../../store/slices/downloadFileSlice.js';
import '../../store/slices/itemModalSlice.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '../../constants/messages.js';
import '../../services/requestApi.js';
import '../../services/userApi.js';
import '../../services/itemApi.js';
import '../../services/requestDetailsApi.js';
import '@pdftron/webviewer';
import { useIcon } from '../../hooks/useIcon.js';
import '../item/utils.js';
import 'react-router-dom';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../../store/thunks/downloadFileThunk.js';
import { encImcUrl } from '../../constants/const.js';

const StyledImage = styled('img') ``;
const SnapshotsGrid = ({ data, id, key, onSelect, pageSize = 30, pageSizes = [30, 60, 100], selectedSnapshot, onDataChange, snapshotMembers }) => {
    const gridRef = useRef(null);
    const pageRef = useRef(null);
    const selectedRef = useRef(null);
    const snapshotMembersRef = useRef(null);
    const [selectedRow, setSelectedRow] = useState(undefined);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(pageSize);
    const _selectedIndex = gridRef?.current?.getSelectedRowIndexes();
    const { dataManager } = useSnapshotsData({
        gridData: data,
        id
    });
    const filterSettings = {
        type: 'Menu',
    };
    const sortingOptions = {
        columns: [{ field: 'DateCaptured', direction: 'Descending' }]
    };
    const selectionSettings = { mode: 'Row', type: 'Single' };
    const dateFormat = { type: 'date', format: 'dd MMM yyy' };
    const debouncedSelect = debounce((args) => {
        const computedIndex = args?.rowIndex + (pageRef?.current?.currentPage - 1) * pageRef?.current?.pageSize;
        const finalIndex = computedIndex >= 0 ? computedIndex : -1;
        // There are some extra clicks where both target and selectedRef are null
        // Those clicks are not needed to be handled
        if (args.name === 'rowSelected' && (args?.target || selectedRef?.current)) {
            let selectedData = args?.data;
            const incorrectRow = selectedRef?.current &&
                args.data &&
                selectedRef?.current?.RelatedObjId !== args.data.RelatedObjId &&
                selectedRef?.current?.selectedIndex === finalIndex;
            if (gridRef?.current && !incorrectRow && args.data) {
                selectedData = {
                    ...selectedData,
                    selectedIndex: finalIndex,
                    selectedPageIndex: pageRef?.current?.currentPage,
                    selectedPageSize: pageRef?.current?.pageSize
                };
                selectedRef.current = selectedData;
                onSelect && onSelect([selectedData]);
                setSelectedRow(selectedData);
            }
        }
        else if (args.name === 'rowDeselected' && (args?.target || selectedRef?.current)) {
            const correctRow = selectedRef?.current &&
                args.data &&
                selectedRef?.current?.RelatedObjId === args.data.RelatedObjId &&
                selectedRef?.current?.selectedIndex === finalIndex;
            if (gridRef.current &&
                selectedRef?.current?.selectedPageIndex === pageRef?.current?.currentPage &&
                selectedRef?.current?.selectedPageSize === pageRef?.current?.pageSize &&
                correctRow) {
                gridRef.current.clearSelection();
                selectedRef.current = null;
                onSelect && onSelect([]);
                setSelectedRow(null);
            }
            else if (!selectedRef?.current) {
                // Clears selection if selectedRef is null but hanleSelect's deselect is triggered
                gridRef.current.clearSelection();
                selectedRef.current = null;
                onSelect && onSelect([]);
                setSelectedRow(null);
            }
        }
    }, 250);
    //allow for multiple select in case it is used in future
    const handleSelect = (args) => {
        // Debounce needed because there are times where Syncfusion deselects the current row before selecting a new row and this causes issues with Webviewer
        debouncedSelect(args);
    };
    // use effect here to detect page changes, set initial selected, etc
    useEffect(() => {
        if (selectedSnapshot && !selectedRow) {
            const selectedSnapshotIndex = selectedSnapshot?.selectedIndex - (selectedSnapshot?.selectedPageIndex - 1) * selectedSnapshot?.selectedPageSize;
            if (gridRef?.current)
                gridRef.current.selectRow(selectedSnapshotIndex);
            else
                selectedRef.current = {
                    initialized: true
                };
        }
        else if (selectedSnapshot && selectedRow) {
            const selectedRowIndex = selectedRow?.selectedIndex - (pageRef.current.currentPage - 1) * pageRef.current.pageSize;
            if (selectedRow?.selectedPageSize === pageRef.current.pageSize &&
                selectedRow?.selectedPageIndex !== pageRef.current.currentPage) {
                // Ensures selectedRow is inside current page
                gridRef.current.selectRow(-1);
            }
            else if (selectedRow?.selectedPageSize === pageRef.current.pageSize &&
                selectedRow?.selectedPageIndex === pageRef.current.currentPage &&
                _selectedIndex[0] !== selectedRowIndex) {
                if (_selectedIndex?.length === 0 || (_selectedIndex?.length > 0 && _selectedIndex[0] !== selectedRowIndex)) {
                    gridRef.current.selectRow(selectedRowIndex);
                }
            }
        }
        else {
            if (gridRef?.current)
                gridRef.current.selectRow(-1);
        }
    }, [currentPage, currentPageSize, selectedSnapshot, selectedRow, _selectedIndex]);
    useEffect(() => {
        snapshotMembersRef.current = snapshotMembers;
    }, [snapshotMembers]);
    const gridData = DataUtil.parse.parseJson(JSON.stringify(data));
    // Ensures that in scenarios where data changes, correct row is still selected
    const handleDataBound = () => {
        if (gridRef?.current) {
            onDataChange && onDataChange({ pageSize: pageRef?.current?.pageSize, currentPage: pageRef?.current?.currentPage });
            const selectedIndices = gridRef.current.getSelectedRowIndexes();
            const _rowObject = selectedRef?.current?.RelatedObjId ? selectedRef?.current : selectedSnapshot;
            const _index = _rowObject?.selectedIndex - (pageRef?.current?.currentPage - 1) * pageRef?.current?.pageSize;
            if ((selectedRef?.current &&
                selectedIndices?.[0] !== _index &&
                pageRef?.current?.currentPage === selectedRef?.current?.selectedPageIndex &&
                pageRef?.current?.pageSize === selectedRef?.current?.selectedPageSize) || (selectedSnapshot &&
                !selectedRef?.current?.RelatedObjId) || (selectedRef?.current &&
                pageRef?.current?.pageSize !== selectedRef?.current?.selectedPageSize &&
                _index > ((pageRef?.current?.currentPage - 1) * pageRef?.current?.pageSize) - 1 &&
                _index < (pageRef?.current?.currentPage * pageRef?.current?.pageSize))) {
                gridRef.current.selectRow(_index);
            }
        }
    };
    //column widths only work if they are numbers so they have been set to pixels
    const Grid = useMemo(() => !!gridData || !!dataManager ? (jsxs(GridComponent, { dataSource: gridData ?? dataManager, dataBound: handleDataBound, allowResizing: true, allowSorting: true, allowFiltering: true, rowSelected: handleSelect, rowDeselected: handleSelect, width: '100%', height: '100%', 
        //need the id to maintain persisted data
        id: 'snapshots-grid', "data-testid": 'snapshots-grid', filterSettings: filterSettings, sortSettings: sortingOptions, selectionSettings: selectionSettings, ref: gridRef, allowPaging: true, pageSettings: { pageSize, pageSizes, currentPage: selectedSnapshot?.selectedPageIndex ?? 1 }, pagerTemplate: (pagerData) => {
            pageRef.current = pagerData;
            // Ensures that the useEffect is called when the page or page size changes
            setCurrentPage(pagerData.currentPage);
            setCurrentPageSize(pagerData.pageSize);
            const pagerDataWithTotalPages = {
                ...pagerData,
                totalPages: Math.ceil(pagerData.totalRecordsCount / pagerData.pageSize),
            };
            return (jsx(PagerTemplate, { pagerData: pagerDataWithTotalPages, grid: gridRef?.current, pageSizes: pageSizes, compact: true }, key));
        }, children: [jsx(Inject, { services: [
                    Filter,
                    Page,
                    Resize,
                    Sort
                ] }), jsxs(ColumnsDirective, { children: [jsx(ColumnDirective, { field: 'DisplayName', headerText: 'Snapshot', minWidth: 200, width: 200, allowEditing: false, allowSorting: false, allowFiltering: false, template: (item) => {
                            return item?.DisplayName;
                        } }, 'DisplayName'), jsx(ColumnDirective, { field: 'DateCaptured', headerText: 'Date Captured', minWidth: 150, width: 150, allowEditing: false, allowSorting: true, allowFiltering: true, type: 'date', format: dateFormat }, 'DateCaptured'), jsx(ColumnDirective, { field: 'HistoryType', headerText: 'Change', minWidth: 175, width: 175, allowEditing: false, allowSorting: true, allowFiltering: true, template: (item) => {
                            return item?.HistoryType === 'Membership' ? item?.Description : item?.HistoryType;
                        }, filter: { type: 'CheckBox' } }, 'HistoryType'), jsx(ColumnDirective, { field: 'Description', headerText: 'Additional Information', allowEditing: false, allowSorting: false, allowFiltering: false, template: (item) => {
                            if (item?.HistoryType !== 'Membership') {
                                return jsx("div", { style: { padding: '0.5rem' }, children: item?.Description });
                            }
                            else {
                                const snapshotItem = snapshotMembersRef?.current?.find((x) => x.ID === item?.RelatedObjId);
                                const itemImageId = snapshotItem?.BusinessType?._ImageId ?? snapshotItem?._ImageId ?? snapshotItem?.TypeDef?._ImageId;
                                const { iconDetails } = useIcon({
                                    imageId: itemImageId,
                                });
                                return (jsx(Chip, { sx: { width: 'auto', maxWidth: '100%' }, title: snapshotItem?.DisplayName, border: false, icon: iconDetails ? (jsx(StyledImage, { style: {
                                            width: '1.5rem',
                                            height: '1.5rem',
                                        }, alt: iconDetails?.AltText, src: `${encImcUrl}${iconDetails?.Url}` })) : null }));
                            }
                        } }, 'Description')] })] }, key)) : (jsx(Fragment, {})), [gridData, dataManager, key, snapshotMembers]);
    return (jsx(Box, { background: 'none', style: { display: 'flex', flex: '1 1 auto' }, children: Grid }));
};

export { SnapshotsGrid, SnapshotsGrid as default };
