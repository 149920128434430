import { jsx, Fragment, jsxs } from 'react/jsx-runtime';
import { Divider } from '@mui/material';
import '../../types/requestTypes.js';
import { ITEM_TYPE } from '../../types/itemTypes.js';
import { InfoPanel } from '../InfoPanel.js';
import { ItemTypeConversationPanel } from './type/ItemTypeConversationPanel.js';
import { ItemTypeEmailPanel } from './type/ItemTypeEmailPanel.js';
import { ItemTypeEventPanel } from './type/ItemTypeEventPanel.js';
import { ItemTypeImagePanel } from './type/ItemTypeImagePanel.js';
import { ItemTypeItemCollectionPanel } from './type/ItemTypeItemCollectionPanel.js';
import { ItemTypeItemPanel } from './type/ItemTypeItemPanel.js';
import { ItemTypeTaskPanel } from './type/ItemTypeTaskPanel.js';
import { ItemTypeVideoPanel } from './type/ItemTypeVideoPanel.js';
import { ItemTypePostPanel } from './type/ItemTypePostPanel.js';

const ItemTypePanel = ({ item }) => {
    const itemType = item?.TypeDef?.Name;
    const itemTypeDisplayName = item?.TypeDef?._Display;
    const newItem = {
        ...item,
        DisplayName: item?.DisplayName ? item?.DisplayName : '(no name)',
    };
    const renderComponent = () => {
        switch (itemType) {
            case ITEM_TYPE.CONVERSATION:
                return jsx(ItemTypeConversationPanel, { item: newItem });
            case ITEM_TYPE.DOCUMENT:
            case ITEM_TYPE.ITEM:
                return jsx(ItemTypeItemPanel, { item: newItem });
            case ITEM_TYPE.EMAIL:
                return jsx(ItemTypeEmailPanel, { item: newItem });
            case ITEM_TYPE.EVENT:
                return jsx(ItemTypeEventPanel, { item: newItem });
            case ITEM_TYPE.IMAGE:
                return jsx(ItemTypeImagePanel, { item: newItem });
            case ITEM_TYPE.ITEM_COLLECTION:
                return jsx(ItemTypeItemCollectionPanel, { item: newItem });
            case ITEM_TYPE.TASK:
                return jsx(ItemTypeTaskPanel, { item: newItem });
            case ITEM_TYPE.AUDIO:
            case ITEM_TYPE.VIDEO:
                return jsx(ItemTypeVideoPanel, { item: newItem });
            case ITEM_TYPE.POST:
                return jsx(ItemTypePostPanel, { item: newItem });
            default:
                return null;
        }
    };
    return (jsx(Fragment, { children: itemType ? (jsxs(Fragment, { children: [jsx(InfoPanel, { title: `${itemTypeDisplayName} Properties`, inset: '3.5rem', style: {
                        paddingRight: '5.25rem',
                        paddingTop: '0.988rem',
                        paddingBottom: '1.031rem',
                    }, children: renderComponent() }), jsx(Divider, { style: {
                        width: '88%',
                        borderColor: '#DCE1EF',
                        margin: '0 auto',
                    } })] })) : null }));
};

export { ItemTypePanel };
