import { jsx, jsxs } from 'react/jsx-runtime';
import { createElement } from '@syncfusion/ej2-base';
import { DataManager } from '@syncfusion/ej2-data';
import { TreeGridComponent, ColumnsDirective, ColumnDirective, Inject, Resize, Reorder, Sort, Freeze, Page, ColumnMenu, Filter, ColumnChooser } from '@syncfusion/ej2-react-treegrid';
import { DisposalRequestStatus } from '../../types/requestTypes.js';
import { ItemGridQuery } from '../../types/itemTypes.js';
import { Box } from '../Box.js';
import { DetailDialog } from '../dialog/DetailDialog.js';
import { MemoizedCommentTemplate } from './templates/CommentTemplate.js';
import { ItemSummaryTemplate } from './templates/ItemSummaryTemplate.js';
import '@mui/material';
import { MemoizedPagerTemplate } from './templates/PagerTemplate.js';
import '@fluentui/react-icons';
import { forwardRef, useEffect, useMemo } from 'react';
import { MemoizedRetentionClassTemplate } from './templates/RetentionClassTemplate.js';
import { MemoizedSummaryTemplate } from './templates/SummaryTemplate.js';
import { MemoizedDisposalStatusTemplate } from './templates/DisposalStatusTemplate.js';
import { OpenInContextMenu } from './components/OpenInContextMenu.js';
import useItemsGrid from './useItemsGrid.js';
import { MemoizedBusinessTypeTemplate } from './templates/BusinesssTypeTemplate.js';
import '../../config.js';
import '../../auth/config.js';
import '../../auth/AuthProvider.js';
import '@azure/msal-react';
import { formatDestructionDate } from '../../util/dateTime.js';
import 'react-redux';
import '@testing-library/react';
import '../../themes/light.theme.js';
import '../../store/store.js';
import 'redux-persist/integration/react';
import '../../store/slices/applicationSlice.js';
import '../../store/slices/downloadFileSlice.js';
import '../../store/slices/itemModalSlice.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '../../constants/messages.js';
import '../../services/requestApi.js';
import '../../services/userApi.js';
import '../../services/itemApi.js';
import '../../services/requestDetailsApi.js';
import '@pdftron/webviewer';
import 'lodash';
import '../item/utils.js';
import 'react-router-dom';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../../store/thunks/downloadFileThunk.js';
import { MemoizedItemManagementStatusChip } from '../chip/ItemManagementStatusChip.js';
import '../chip/PerspectiveClassChip.js';
import { Typography } from '../Typography.js';
import { MemoizedDestructionFailureTemplate } from './templates/DestructionFailureTemplate.js';
import { DropDownList } from '@syncfusion/ej2-react-dropdowns';

const ItemsGrid = forwardRef(({ data, filter, disposalId, itemGridType, persistanceId, pageSize = 30, disposalRequest, selectedRecords, adminMode = false, isItemPanel = false, testId = 'ItemsGrid', pageSizes = [30, 60, 100], selectedRecordsForRemoval, onOpen, onSelect, onOpenURL, onOpenItems, onOpenDisposalRequest, onOpenDisposalRequestContextMenu, isItemCollection = false, levels = [], startDate = '', endDate = '' }, gridRef) => {
    const showCheckbox = (disposalRequest?.Status === DisposalRequestStatus.New ||
        disposalRequest?.Status === DisposalRequestStatus.Rejected) &&
        !isItemPanel; // remove the checkbox if the items grid is from the item panel.
    let stateValue;
    const dateFormat = { type: 'date', format: 'dd MMM yyy' };
    const filterSettings = {
        type: 'Menu',
    };
    const initialSortValue = levels[levels.length - 1]?.sortSettings
        ? [levels[levels.length - 1]?.sortSettings]
        : [];
    const initialPageSize = levels[levels.length - 1]?.pageSize
        ? levels[levels.length - 1]?.pageSize
        : 30;
    const sortingOptions = {
        columns: initialSortValue,
    };
    const { key, comment, setComment, rowSelected, rowDeselected, getDataSource, dataStateChange, contextAnchor, setContextAnchor, contextItem, setContextItem, failureDetailsLookup, } = useItemsGrid({
        onSelect,
        pageSize: initialPageSize,
        disposalId,
        data,
        adminMode,
        filter,
        itemGridType,
        selectedRecords,
        selectedRecordsForRemoval,
        gridRef,
        stateValue,
        showCheckbox,
        isItemPanel,
        initialSortValue,
        levels,
        startDate,
        endDate
    });
    const handleOpenInContextMenu = (target) => {
        setContextAnchor(null);
        onOpen && contextItem && onOpen(contextItem, target);
    };
    const handleCloseInContextMenu = () => setContextAnchor(null);
    let dropInstance;
    const FilterType = {
        ui: {
            create: (args) => {
                const flValInput = createElement('input', { className: 'flm-input' });
                args.target.appendChild(flValInput);
                dropInstance = new DropDownList({
                    dataSource: new DataManager(failureDetailsLookup),
                    fields: { text: 'Caption', value: 'Name' },
                    placeholder: 'Select a value',
                    popupWidth: 'auto',
                });
                dropInstance.appendTo(flValInput);
            },
            read: (args) => {
                args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstance.value);
            },
            write: (args) => {
                dropInstance.value = args.filteredValue;
            },
        },
    };
    const actionComplete = (props) => {
        // For some reason sort is lost after changing pages
        if (props?.requestType === 'sorting') {
            stateValue = {
                ...stateValue,
                sortSettings: {
                    direction: props.direction,
                    field: props.columnName,
                },
            };
        }
        if (props?.requestType === 'paging') {
            stateValue = {
                currentPage: props.currentPage,
                pageSize: props.pageSize,
            };
        }
    };
    useEffect(() => {
        if (stateValue)
            stateValue = undefined;
    }, [levels]);
    const TreeGrid = () => {
        const gridId = isItemPanel && levels?.length
            ? levels[levels?.length - 1]?.id
            : disposalId;
        const newPage = levels[levels.length - 1]?.pageIndex && levels?.length
            ? levels[levels.length - 1]?.pageIndex
            : 1;
        const newPageSize = levels[levels.length - 1]?.pageSize && levels?.length
            ? levels[levels.length - 1]?.pageSize
            : pageSize;
        const selectedRow = levels[levels.length - 1]?.rowIndex >= 0 && levels?.length
            ? levels[levels.length - 1]?.rowIndex
            : -1;
        return (jsxs(TreeGridComponent
        // enableCollapseAll //TODO: Add when treegrid nesting is required
        , { 
            // enableCollapseAll //TODO: Add when treegrid nesting is required
            allowReordering: true, dataSource: !!data ? data : [], dataBound: !!data ? undefined : getDataSource, dataStateChange: !!data ? undefined : dataStateChange, loadingIndicator: { indicatorType: 'Shimmer' }, ref: gridRef, showColumnChooser: true, treeColumnIndex: showCheckbox ? 1 : 0, allowResizing: true, actionComplete: actionComplete, parentIdMapping: 'ParentId', allowSorting: true, "data-testid": testId, hasChildMapping: 'HasMember', className: 'items-grid-tree-grid', idMapping: 'ID', height: '100%', width: '100%', childMapping: !!data ? 'Items' : undefined, 
            //need the id to maintain persisted data
            id: `${!!persistanceId ? persistanceId + itemGridType : 'ItemGridl10'}-${gridId}`, allowPaging: true, allowFiltering: true, pageSettings: {
                pageSize: stateValue?.pageSize ?? newPageSize,
                currentPage: newPage,
            }, selectedRowIndex: selectedRow, pagerTemplate: (pagerData) => {
                const pagerDataWithTotalPages = {
                    ...pagerData,
                    pageSize: pagerData.pageSize,
                    totalPages: Math.ceil(pagerData.totalRecordsCount / pagerData.pageSize),
                };
                return (jsx(MemoizedPagerTemplate, { pagerData: pagerDataWithTotalPages, grid: gridRef?.current, pageSizes: pageSizes }, key));
            }, filterSettings: filterSettings, sortSettings: sortingOptions, rowSelected: rowSelected, rowDeselected: rowDeselected, children: [jsxs(ColumnsDirective, { children: [jsx(ColumnDirective, { type: 'checkbox', width: '40', showInColumnChooser: false, field: 'Checkbox', allowFiltering: false }), jsx(ColumnDirective, { field: 'DisplayName', headerText: itemGridType === ItemGridQuery.DestroyItems
                                ? 'Item Summary'
                                : 'Item', clipMode: 'Ellipsis', showCheckbox: false, allowResizing: true, showInColumnChooser: false, width: '5rem', template: (props) => {
                                const item = props;
                                // Icon shown is in order of availability and priority:
                                // 1. Business Type Icon
                                // 2. File Type Icon
                                // 3. Type Def Icon
                                const itemImageId = item?.BusinessType?._ImageId ?? item?._ImageId ?? item?.TypeDef?._ImageId;
                                return (jsx(ItemSummaryTemplate, { adminMode: adminMode, title: item?.DisplayName ? item?.DisplayName : '(no name)', itemId: item.ID, disposalRequest: disposalRequest, filter: filter, imageId: itemImageId ?? undefined, itemGridType: itemGridType, type: item.AttachedItems?.value?.length ? 'attachment' : 'item', count: !!item.Members
                                        ? item.Members['@odata.count']
                                        : item.AttachedItems?.value.length ?? 0, tags: [
                                        item.BusinessType?.Caption.toUpperCase() ?? '',
                                        item.TypeDef?.Caption.toUpperCase() ?? '',
                                    ].filter((x) => x != ''), onClick: () => {
                                        if (isItemCollection || !!startDate) {
                                            const initialValue = stateValue
                                                ? stateValue?.currentPage
                                                : levels[levels.length - 1]?.pageIndex;
                                            const initialPageSize = stateValue
                                                ? stateValue?.pageSize
                                                : levels[levels.length - 1]?.pageSize;
                                            const initialSort = stateValue
                                                ? stateValue?.sortSettings
                                                : levels[levels.length - 1]?.sortSettings;
                                            const itemMember = {
                                                ...item,
                                                page: initialValue ?? 1,
                                                pageSize: initialPageSize ?? 30,
                                                sortSettings: initialSort ?? {},
                                            };
                                            onOpen && onOpen(itemMember, 'modal');
                                        }
                                        else
                                            onOpen && onOpen(item, 'modal');
                                    }, onClickItems: () => {
                                        const initialValue = stateValue
                                            ? stateValue?.currentPage
                                            : levels[levels.length - 1]?.pageIndex;
                                        const initialPageSize = stateValue
                                            ? stateValue?.pageSize
                                            : levels[levels.length - 1]?.pageSize;
                                        const initialSort = stateValue
                                            ? stateValue?.sortSettings
                                            : levels[levels.length - 1]?.sortSettings;
                                        const itemMember = {
                                            ...item,
                                            page: initialValue ?? 1,
                                            pageSize: initialPageSize ?? 30,
                                            sortSettings: initialSort ?? {},
                                        };
                                        onOpenItems && onOpenItems(itemMember, 'modal');
                                    }, onContextMenu: (e) => {
                                        setContextItem(item);
                                        setContextAnchor(e.currentTarget);
                                    }, isSnapshotMemberGrid: itemGridType === ItemGridQuery.SnapshotMembers }));
                            }, minWidth: '18rem', allowFiltering: false }, 'DisplayName'), jsx(ColumnDirective, { field: 'StorageObject.FileExtension', headerText: 'Extension', allowFiltering: false }, 'StorageObject.FileExtension'), jsx(ColumnDirective, { field: 'CreatedBy.DisplayName', headerText: 'Created By', allowFiltering: false }, 'CreatedBy.DisplayName'), jsx(ColumnDirective, { field: 'DateCreated', headerText: 'Date Created', type: 'date', format: dateFormat, allowFiltering: false }, 'DateCreated'), jsx(ColumnDirective, { field: 'DateModified', headerText: 'Date Modified', type: 'date', format: dateFormat, allowFiltering: false }, 'DateModified'), jsx(ColumnDirective, { field: 'DisposalRequests.Name', headerText: 'Disposal Requests', allowFiltering: false, showInColumnChooser: itemGridType === ItemGridQuery.DestroyItems &&
                                filter === DisposalRequestStatus.Failed, template: (item) => {
                                const _disposalDetailsDisposalRequests = item?.DisposalDetails?.value.map((disposalDetail) => {
                                    const _disposalRequest = disposalDetail.DisposalRequest;
                                    return _disposalRequest
                                        ? {
                                            ID: _disposalRequest.ID,
                                            Name: _disposalRequest.Name,
                                            RequestedBy: '',
                                            ItemCount: 0,
                                            IsRead: false,
                                        }
                                        : {
                                            ID: null,
                                            Name: '',
                                            RequestedBy: '',
                                            ItemCount: 0,
                                            IsRead: false,
                                        };
                                });
                                const _disposalRequests = _disposalDetailsDisposalRequests;
                                return (jsx(Box, { background: 'none', style: {
                                        marginTop: _disposalRequests?.length > 1 ? '0.625rem' : '0',
                                    }, children: _disposalRequests?.map((request) => {
                                        const _subTitle = request.ItemCount
                                            ? `Destroy ${request.ItemCount ?? ''} Items`
                                            : undefined;
                                        const _information = request.RequestedBy &&
                                            `Requested by ${request.RequestedBy?.DisplayName}`;
                                        return (jsx(Box, { background: 'none', style: { minHeight: '3.125rem' }, children: jsx(MemoizedSummaryTemplate, { title: request.Name, disposalId: request.ID, subTitle: _subTitle, information: _information, isUnread: !request.IsRead, onClick: () => !!onOpenDisposalRequest &&
                                                    onOpenDisposalRequest(request), onContextMenu: (e) => !!onOpenDisposalRequestContextMenu &&
                                                    onOpenDisposalRequestContextMenu(e, request) }) }));
                                    }) }));
                            } }, 'DisposalRequests'), jsx(ColumnDirective, { field: 'DisposalStates', headerText: 'Disposal Status', allowFiltering: false, template: (item) => (jsx(MemoizedDisposalStatusTemplate, { item: item, disposalRequest: disposalRequest })) }, 'DisposalStates'), jsx(ColumnDirective, { field: 'Status', headerText: itemGridType === ItemGridQuery.DestroyItems
                                ? 'Item Status'
                                : 'Status', allowFiltering: false, template: (item) => {
                                return (jsx(MemoizedItemManagementStatusChip, { status: item.Status, border: itemGridType !== ItemGridQuery.DestroyItems }));
                            } }, 'Status'), jsx(ColumnDirective, { field: 'DisposalDetails.CompletedDate', headerText: 'Date Destroyed', template: (item) => {
                                const formattedData = item?.DisposalDetails?.value?.[0]
                                    ?.CompletedDate
                                    ? formatDestructionDate(item?.DisposalDetails?.value?.[0]?.CompletedDate)
                                    : null;
                                return jsx(Typography, { variant: 'body1', children: formattedData });
                            }, allowFiltering: false }, 'DisposalDetails'), jsx(ColumnDirective, { field: 'Classifications', headerText: 'Retention Classes', clipMode: 'Ellipsis', template: (item) => (jsx(MemoizedRetentionClassTemplate, { classifications: item.Classifications?.value ?? [] })), allowFiltering: false }, 'Classifications'), jsx(ColumnDirective, { field: 'BusinessType', headerText: 'Business Type', template: (item) => {
                                return ((item.BusinessType?.Caption ?? '') !== '' && (jsx(MemoizedBusinessTypeTemplate, { title: item.BusinessType?.Caption ?? '', imageId: !!item.BusinessType
                                        ? item.BusinessType._ImageId
                                        : undefined, information: !!item?.BusinessType?.DerivedPath
                                        ? item.BusinessType.DerivedPath
                                        : undefined })));
                            }, allowFiltering: false }, 'BusinessType'), jsx(ColumnDirective, { field: 'DisposalDetails.FailureDetail', filter: FilterType, headerText: 'Destruction Failure', showInColumnChooser: itemGridType === ItemGridQuery.DestroyItems &&
                                filter === DisposalRequestStatus.Failed, template: (item) => (jsx(MemoizedDestructionFailureTemplate, { item: item })), allowFiltering: true, allowSorting: true, type: 'string' }, 'DisposalDetails'), jsx(ColumnDirective, { field: 'RepositoryURL', headerText: 'Repository URL', template: (item) => (jsx(MemoizedCommentTemplate, { comment: item.RepositoryUrl, isURL: true, onClick: onOpenURL, numberOfLines: 3 })), allowFiltering: false }, 'RepositoryURL')] }), jsx(Inject, { services: [
                        Resize,
                        Reorder,
                        Sort,
                        Freeze,
                        Page,
                        ColumnMenu,
                        Filter,
                        ColumnChooser,
                    ] })] }));
    };
    // Need to implement a useMemo here to fix the rendering issue.
    const MemoizedTreeGridComponent = useMemo(() => jsx(TreeGrid, {}), [filter, data, failureDetailsLookup, JSON.stringify(levels)]);
    return (
    //wrap with a box so that scrolling in the grid works
    jsxs(Box, { background: 'none', children: [isItemPanel ? jsx(TreeGrid, {}) : MemoizedTreeGridComponent, jsx(DetailDialog, { title: 'Comment', open: !!comment, onClose: () => setComment(null), children: comment }), jsx(OpenInContextMenu, { anchor: contextAnchor, options: ['new tab', 'new window'], onOpen: handleOpenInContextMenu, onClose: handleCloseInContextMenu })] }));
});

export { ItemsGrid, ItemsGrid as default };
