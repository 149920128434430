import { jsx, Fragment, jsxs } from 'react/jsx-runtime';
import { styled, Divider, Typography } from '@mui/material';
import { DisposalRequestStatus } from '../../types/requestTypes.js';
import '../../types/itemTypes.js';
import { InfoPanel } from '../InfoPanel.js';
import { DetailsRow } from '../DetailsRow.js';
import { Chip } from '../chip/Chip.js';
import { Add12Regular } from '@fluentui/react-icons';
import { useState } from 'react';
import '../../themes/light.theme.js';
import '../chip/ItemManagementStatusChip.js';
import { PerspectiveClassChip } from '../chip/PerspectiveClassChip.js';
import { Box } from '../Box.js';
import '../../config.js';
import '../../auth/config.js';
import '../../auth/AuthProvider.js';
import '@azure/msal-react';
import { formatDate } from '../../util/dateTime.js';
import 'react-redux';
import '@testing-library/react';
import '../../store/store.js';
import 'redux-persist/integration/react';
import '../../store/slices/applicationSlice.js';
import '../../store/slices/downloadFileSlice.js';
import '../../store/slices/itemModalSlice.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '../../constants/messages.js';
import '../../services/requestApi.js';
import '../../services/userApi.js';
import { useSaveItemDetailsMutation } from '../../services/itemApi.js';
import '../../services/requestDetailsApi.js';
import '@pdftron/webviewer';
import 'lodash';
import { isBoolean, isInteger, isDouble, isDateTime, isDate, isEnum, isEntity, isMultiline, isSingleLine } from './utils.js';
import { isPerspectiveClass, hasDisplayProperty } from './ItemPropertiesUtil.js';
import 'react-router-dom';
import { useBusinessTypePanel } from '../../hooks/useBusinessTypePanel.js';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../../store/thunks/downloadFileThunk.js';
import { TextIconButton } from '../button/TextIconButton.js';
import '@dnd-kit/utilities';
import '@mui/lab';
import { EditBooleanDialog } from '../dialog/EditBooleanDialog.js';
import { EditBusinessTypeDialog } from '../dialog/EditBusinessTypeDialog.js';
import { EditCustomTypeDialog } from '../dialog/EditCustomTypeDialog.js';
import { EditDateDialog } from '../dialog/EditDateDialog.js';
import { EditDateTimeDialog } from '../dialog/EditDateTimeDialog.js';
import { EditEnumDialog } from '../dialog/EditEnumDialog.js';
import { EditNumberDialog } from '../dialog/EditNumberDialog.js';
import { EditPerspectiveClassDialog } from '../dialog/EditPerspectiveClassDialog.js';
import '../OptionMultiSelect.js';
import '../SortableOptionList.js';
import { EditTextDialog } from '../dialog/EditTextDialog.js';
import '@mui/material/Breadcrumbs';
import '@mui/material/Menu';
import '@mui/material/MenuItem';
import { encImcUrl } from '../../constants/const.js';

var PropertyNames;
(function (PropertyNames) {
    PropertyNames["BUSINESS_TYPE"] = "BusinessType";
})(PropertyNames || (PropertyNames = {}));
const StyledImage = styled('img') ``;
const displayPropertyValue = (obj, editablePropDefs, treeGridProperties, isNested = false) => {
    if (!obj)
        return;
    let hasSingleLineValue = false;
    return Object.entries(obj)?.flatMap(([key, value]) => {
        const propDef = editablePropDefs?.find((value) => value.Name === key);
        if (value == undefined || value == null)
            return;
        if (propDef != undefined) {
            const dataType = propDef?.PrimitiveDataType;
            if (value && !Array.isArray(value?.value) && !isNested)
                hasSingleLineValue = true;
            if (Array.isArray(value?.value) && value?.value?.length) {
                return value?.value?.flatMap((item, index) => {
                    const propertyTypeDefId = item?.TypeDefId;
                    const nestedEditablePropDefs = treeGridProperties?.TypeData?.value?.find((property) => property?.TreeDataTypeDef?.ID === propertyTypeDefId)?.EditablePropDefs?.value;
                    if (nestedEditablePropDefs) {
                        return (jsx(Box, { background: 'none', style: {
                                marginBottom: index + 1 < value?.value?.length ? '2rem' : '0',
                                marginTop: index == 0 && hasSingleLineValue ? '2rem' : '0',
                            }, children: displayPropertyValue(item, nestedEditablePropDefs, treeGridProperties, true) }));
                    }
                });
            }
            if (isDate(propDef) || isDateTime(propDef)) {
                const isDateTime = dataType === 'DateTime';
                const formattedDate = value ? formatDate(value, isDateTime) : '';
                return (jsx(Typography, { variant: 'body2', sx: { paddingBottom: '0.25rem' }, children: formattedDate }));
            }
            if (isBoolean(propDef)) {
                const nullableValue = propDef?.Nullable ? '--' : 'No';
                const booleanValue = value == true ? 'Yes' : 'No';
                const fieldValue = value ? booleanValue : nullableValue;
                return (jsx(Typography, { variant: 'body2', sx: { paddingBottom: '0.25rem' }, children: fieldValue }));
            }
            if (isPerspectiveClass(value)) {
                return jsx(PerspectiveClassChip, { businessClass: value });
            }
            if (typeof value === 'object' && !Array.isArray(value?.value)) {
                const propertyTypeDefId = value?.TypeDefId;
                const nestedEditablePropDefs = treeGridProperties?.TypeData?.value?.find((property) => property?.TreeDataTypeDef?.ID === propertyTypeDefId)?.EditablePropDefs?.value;
                return displayPropertyValue(value, nestedEditablePropDefs, treeGridProperties);
            }
            const nullableValue = propDef?.Nullable ? '--' : '';
            const numberValue = value !== null ? value : nullableValue;
            const fieldValue = isInteger(propDef) || isDouble(propDef) ? numberValue : value;
            return (jsx(Typography, { variant: 'body2', sx: { paddingBottom: '0.25rem' }, children: fieldValue }, `${key}-${value}`));
        }
    });
};
const getContentData = (propertyValue, propDef, treeGridProperties) => {
    // Only boolean and integers are currently supported to show --
    if (propertyValue == null && !isBoolean(propDef) && !isInteger(propDef) && !isDouble(propDef))
        return;
    if (propertyValue?.value?.length) {
        return propertyValue.value.map((val, index) => {
            const propertyTypeDefId = val?.TypeDefId;
            const editablePropDefs = treeGridProperties?.TypeData?.value?.find((property) => property?.TreeDataTypeDef?.ID === propertyTypeDefId)?.EditablePropDefs?.value;
            return (jsx(Box, { background: 'none', style: {
                    marginBottom: index + 1 < propertyValue?.value?.length ? '2rem' : '0',
                }, children: displayPropertyValue(val, editablePropDefs, treeGridProperties, true) }));
        });
    }
    else {
        if (isDateTime(propDef) || isDate(propDef)) {
            const isDateTime = propDef?.PrimitiveDataType === 'DateTime';
            const formattedDate = propertyValue
                ? formatDate(propertyValue, isDateTime)
                : '';
            return (jsx(Typography, { variant: 'body2', sx: { minHeight: '2.5rem' }, children: formattedDate }));
        }
        if (isBoolean(propDef)) {
            const nullableValue = propDef?.Nullable ? '--' : 'No';
            const booleanValue = propertyValue == true ? 'Yes' : 'No';
            const fieldValue = propertyValue ? booleanValue : nullableValue;
            return (jsx(Typography, { variant: 'body2', sx: { minHeight: '2.5rem' }, children: fieldValue }));
        }
        if (isPerspectiveClass(propertyValue)) {
            return jsx(PerspectiveClassChip, { businessClass: propertyValue });
        }
        if (hasDisplayProperty(propertyValue) &&
            !Array.isArray(propertyValue?.value)) {
            const propertyTypeDefId = propertyValue?.TypeDefId;
            const editablePropDefs = treeGridProperties?.TypeData?.value?.find((property) => property?.TreeDataTypeDef?.ID === propertyTypeDefId)?.EditablePropDefs?.value;
            return (jsx(Box, { background: 'none', children: displayPropertyValue(propertyValue, editablePropDefs, treeGridProperties) }));
        }
        const nullableValue = propDef?.Nullable ? '--' : '';
        const numberValue = propertyValue !== null ? propertyValue : nullableValue;
        const fieldValue = isInteger(propDef) || isDouble(propDef) ? numberValue : propertyValue;
        return (jsx(Typography, { variant: 'body2', sx: { minHeight: '2.5rem', whiteSpace: 'pre-wrap' }, children: `${fieldValue}` }));
    }
};
const ItemBusinessTypePanel = ({ item, hasEditPermission, disposalRequest, treeGridProperties, businessTypeProperties, businessTypeIconDetails, businessTypePropertyValues, }) => {
    const [dialogProps, setDialogProps] = useState({
        open: false,
    });
    const { formDetails } = useBusinessTypePanel({
        item,
        businessTypeProperties,
        businessTypePropertyValues,
    });
    const [saveItemDetails, { isLoading }] = useSaveItemDetailsMutation();
    const setDialogDetails = ({ open, title, fieldLabel, propertyName, propertyValue, propertyDetails, }) => {
        setDialogProps({
            open,
            title,
            fieldLabel,
            propertyName,
            propertyValue,
            propertyDetails,
        });
    };
    item.BusinessObject
        ? item.BusinessObject.TypeDefId
        : item.BusinessType
            ? item.BusinessType.ID
            : item.BusinessTypeId;
    item.BusinessObject;
    const handleClose = () => {
        setDialogProps({
            open: false,
        });
    };
    const handleSave = async (value, propertyName) => {
        // this is to prevent from calling the API again.
        if (isLoading)
            return;
        const { ID, TypeDefId } = item;
        let formData = {};
        // Construct Business Type Request
        if (propertyName === PropertyNames.BUSINESS_TYPE) {
            formData = { BusinessType: value };
        }
        // Construct Custom Type Request (Business Type Properties)
        else {
            formData = {
                ...formDetails,
                BusinessObject: {
                    ...formDetails.BusinessObject,
                    [propertyName]: value?.[propertyName] ?? value ?? null,
                },
            };
        }
        const requestObj = {
            ID,
            TypeDefId,
            formDetails: formData,
        };
        try {
            const result = await saveItemDetails(requestObj);
            setDialogDetails({ open: false });
            console.log('Item Details updated:', result);
        }
        catch (error) {
            // Handle error
            console.error('Error on updating the item details:', error);
            setDialogDetails({ open: false });
        }
    };
    const renderEditDialog = () => {
        const { propertyDetails, open, title, propertyName, propertyValue, fieldLabel, } = dialogProps || {};
        const fieldDetails = { ...propertyDetails };
        const isDateType = isDate(fieldDetails);
        const isDateTimeType = isDateTime(fieldDetails);
        const isEnumType = isEnum(fieldDetails);
        const isEntityType = isEntity(fieldDetails);
        const isBooleanType = isBoolean(fieldDetails);
        const isMultilineTextField = isMultiline(fieldDetails);
        const isSingleLineTextField = isSingleLine(fieldDetails);
        const isDoubleType = isDouble(fieldDetails);
        const isIntegerType = isInteger(fieldDetails);
        const isPerspectiveClassType = isEntity(fieldDetails) &&
            fieldDetails?.PropTypeDef?.Name?.indexOf('IT_') == 0;
        // Render Business Type Dialog
        if (propertyName === PropertyNames.BUSINESS_TYPE) {
            return (jsx(EditBusinessTypeDialog, { open: open, title: title, onSave: handleSave, onClose: handleClose, fieldLabel: fieldLabel, propertyName: propertyName, propertyDetails: propertyDetails }));
        }
        // Render Multiline or Single line text field Dialog
        if (isMultilineTextField || isSingleLineTextField) {
            return (jsx(EditTextDialog, { rows: 12, open: open, title: title, label: fieldLabel, onSave: handleSave, text: propertyValue, onClose: handleClose, propertyName: propertyName, multiline: isMultilineTextField, style: {
                    maxWidth: '47.5rem',
                    maxHeight: isMultilineTextField ? '36.375rem' : '20.25rem',
                    width: '100%',
                    height: '100%',
                } }));
        }
        // Render Boolean field Dialog
        if (isBooleanType) {
            return (jsx(EditBooleanDialog, { open: open, title: title, onSave: handleSave, onClose: handleClose, isLoading: isLoading, fieldLabel: fieldLabel, propertyName: propertyName, propertyValue: propertyValue }));
        }
        // Render Date field Dialog
        if (isDateType) {
            return (jsx(EditDateDialog, { open: open, title: title, onSave: handleSave, onClose: handleClose, isLoading: isLoading, fieldLabel: fieldLabel, propertyName: propertyName, propertyValue: formatDate(propertyValue, false) }));
        }
        // Render DateTime field Dialog
        if (isDateTimeType) {
            return (jsx(EditDateTimeDialog, { open: open, title: title, onSave: handleSave, onClose: handleClose, isLoading: isLoading, fieldLabel: fieldLabel, propertyName: propertyName, propertyValue: formatDate(propertyValue) }));
        }
        // Render Number type Dialog
        if (isDoubleType || isIntegerType) {
            return (jsx(EditNumberDialog, { open: open, title: title, onSave: handleSave, onClose: handleClose, isLoading: isLoading, fieldLabel: fieldLabel, propertyName: propertyName, propertyValue: propertyValue, numberType: isDoubleType ? 'decimal' : 'integer' }));
        }
        // Render Enum type Dialog
        if (isEnumType) {
            return (jsx(EditEnumDialog, { open: open, title: title, onSave: handleSave, onClose: handleClose, isLoading: isLoading, fieldLabel: fieldLabel, propertyName: propertyName, propertyValue: propertyValue, propertyDetails: fieldDetails }));
        }
        // Render Perspective Class
        if (isPerspectiveClassType) {
            return (jsx(EditPerspectiveClassDialog, { open: open, title: title, onSave: handleSave, onClose: handleClose, isLoading: isLoading, fieldLabel: fieldLabel, propertyName: propertyName, propertyDetails: fieldDetails, propertyValue: propertyValue }));
        }
        // Render Custom Type
        if (isEntityType) {
            return (jsx(EditCustomTypeDialog, { open: open, title: title, itemId: item?.ID, onSave: handleSave, isLoading: isLoading, onClose: handleClose, typeDefId: item?.TypeDefId, propertyName: propertyName, propertyDetails: fieldDetails, treeGridProperties: treeGridProperties, businessObject: formDetails?.BusinessObject, formValue: formDetails?.BusinessObject[propertyName] }));
        }
    };
    const isItemStatusDestroyed = item?.Status === 'Destroyed';
    const hasBusinessTypeProperties = item?.BusinessType;
    // Add and Edit variables for Business Type
    const hasAddButtonBusinessType = hasEditPermission &&
        !item?.BusinessType &&
        !isItemStatusDestroyed &&
        disposalRequest?.Status !== DisposalRequestStatus.InProgressOrComplete;
    let hasEditButtonBusinessType = hasEditPermission &&
        !!item?.BusinessType &&
        disposalRequest?.Status !== DisposalRequestStatus.InProgressOrComplete;
    return (jsx(Fragment, { children: hasAddButtonBusinessType || hasBusinessTypeProperties ? (jsxs(Fragment, { children: [jsxs(InfoPanel, { inset: '3.5rem', title: `${item?.BusinessType?._Display ?? 'Business Type'} Properties`, style: { paddingRight: '5.25rem', paddingTop: '0.988rem' }, children: [jsx(DetailsRow, { alignData: true, label: 'Business Type', data: hasAddButtonBusinessType ? (jsx(Box, { background: 'none', direction: 'row', children: jsx(TextIconButton, { size: 'medium', color: 'primary', textVariant: 'body2', startIcon: jsx(Add12Regular, {}), onClick: () => setDialogDetails({
                                        open: true,
                                        title: 'Add Business Type',
                                        fieldLabel: 'Business Type',
                                        propertyDetails: item?.BusinessType,
                                        propertyName: PropertyNames.BUSINESS_TYPE,
                                    }), text: 'Add business type' }) })) : (jsx(Box, { background: 'none', width: '100%', style: {
                                    maxWidth: 'fit-content',
                                }, children: jsx(Chip, { sx: { width: 'auto' }, title: item?.BusinessType?._Display, subTitle: item?.BusinessType?.DerivedPath, icon: businessTypeIconDetails ? (jsx(StyledImage, { style: {
                                            width: '1.5rem',
                                            height: '1.5rem',
                                        }, alt: businessTypeIconDetails?.AltText, src: `${encImcUrl}${businessTypeIconDetails?.Url}` })) : null }) })), onEdit: hasEditButtonBusinessType
                                ? () => setDialogDetails({
                                    open: true,
                                    title: 'Edit Business Type',
                                    fieldLabel: 'Business Type',
                                    propertyDetails: item?.BusinessType,
                                    propertyName: PropertyNames.BUSINESS_TYPE,
                                })
                                : undefined }, 'business-type'), hasBusinessTypeProperties
                            ? businessTypeProperties?.PropDefs?.value?.map((businessTypeProperty) => {
                                const propertyValue = businessTypePropertyValues?.[businessTypeProperty.Name];
                                const hasAddButton = propertyValue == null && hasEditPermission;
                                hasEditButtonBusinessType =
                                    hasEditPermission && propertyValue !== null;
                                return (jsx(DetailsRow, { label: businessTypeProperty.Caption, data: jsx(Box, { background: 'none', children: hasAddButton ? (jsx(TextIconButton, { size: 'medium', color: 'primary', textVariant: 'body2', startIcon: jsx(Add12Regular, {}), onClick: () => setDialogDetails({
                                                open: true,
                                                propertyValue,
                                                propertyDetails: businessTypeProperty,
                                                propertyName: businessTypeProperty.Name,
                                                fieldLabel: businessTypeProperty.Caption,
                                                title: `Add ${businessTypeProperty.Caption}`,
                                            }), text: `Add ${businessTypeProperty.Caption.toLowerCase()}`, sx: { height: 'auto' } })) : (jsx(Box, { background: 'none', children: getContentData(propertyValue, businessTypeProperty, treeGridProperties) })) }), onEdit: hasEditButtonBusinessType
                                        ? () => {
                                            setDialogDetails({
                                                open: true,
                                                propertyValue,
                                                propertyDetails: businessTypeProperty,
                                                propertyName: businessTypeProperty.Name,
                                                fieldLabel: businessTypeProperty.Caption,
                                                title: `Edit ${businessTypeProperty.Caption}`,
                                            });
                                        }
                                        : null }, businessTypeProperty.Name));
                            })
                            : null] }), jsx(Divider, { style: {
                        width: '88%',
                        margin: '0 auto',
                        borderColor: '#DCE1EF',
                    } }), renderEditDialog()] })) : null }));
};

export { ItemBusinessTypePanel };
