import { jsxs, jsx } from 'react/jsx-runtime';
import { Dialog, Divider, DialogTitle, Typography, DialogContent } from '@mui/material';
import { CloseButton } from '../button/CustomButton.js';
import { Box } from '../Box.js';
import { AppLogo } from '../AppLogo.js';

const AboutDialog = ({ open, onClose, title, tenant, version, build, commit, content, }) => {
    title = title ?? 'About EncompaaS';
    tenant = tenant ?? '';
    version = version ?? '';
    build = build ?? '';
    commit = commit ?? '';
    content =
        content ??
            'Proudly brought to you by the EncompaaS implementation team.\n\nAndy Saals, Doug Leung, Matt Abraham, Adam Pollard, Chris Hammond-Ross, Dan Theobald, Jaimie Tilbrook, Steve Taylor, Rob Tucker, David Barrie, Andrew Wood, Roger Hogg, Gordon Taylor, Cindy Liga, Melvin Villegas, Rob Hay, Erickson Chua Lee, Alex Overton, Anthony Drabsch, Conor Griffin, Jay Patterson, Paul Libauer, Carlo Dimaranan, Katreena Juntado, Luke Bennis, Marlon Hario, Marcus Powrie, Sal Craig and Kain De Luca';
    return (jsxs(Dialog, { open: open, onClose: onClose, children: [jsxs(Box, { background: 'light', direction: 'row', alignItems: 'center', height: 6, gap: 'small', style: {
                    padding: '0 1.5rem 0 0.75rem',
                    justifyContent: 'space-between',
                }, children: [jsx(AppLogo, { textColour: 'dark' }), jsx(CloseButton, { size: 'small', onClick: onClose })] }), jsx(Divider, { sx: {
                    margin: '0 2rem',
                    border: '0.0625rem solid #D9DDE8',
                } }), jsx(DialogTitle, { sx: {
                    paddingBottom: '0rem',
                    paddingTop: '0rem',
                    backgroundColor: '#FFF',
                    marginBottom: '0',
                }, children: jsx(Typography, { variant: 'h2', children: title }) }), jsxs(DialogContent, { sx: { backgroundColor: '#FFF' }, children: [jsxs(Box, { direction: 'row', background: 'light', gap: 3, children: [jsxs(Box, { background: 'light', width: 5, children: [jsx(Typography, { variant: 'h5', sx: { lineHeight: '1.8rem' }, children: "Tenant" }), jsx(Typography, { variant: 'h5', sx: { lineHeight: '1.8rem' }, children: "Version" }), jsx(Typography, { variant: 'h5', sx: { lineHeight: '1.8rem' }, children: "Build" }), jsx(Typography, { variant: 'h5', sx: { lineHeight: '1.8rem' }, children: "Commit" })] }), jsxs(Box, { background: 'light', children: [jsx(Typography, { variant: 'body2', sx: { lineHeight: '1.8rem' }, children: tenant }), jsx(Typography, { variant: 'body2', sx: { lineHeight: '1.8rem' }, children: version }), jsx(Typography, { variant: 'body2', sx: { lineHeight: '1.8rem' }, children: build }), jsx(Typography, { variant: 'body2', sx: { lineHeight: '1.8rem' }, children: commit })] })] }), jsx(Divider, { sx: {
                            margin: '1.25rem 0 1.25rem 0',
                            border: '0.0625rem solid #D9DDE8',
                        } }), jsx(Typography, { variant: 'body2', sx: { whiteSpace: 'pre-line' }, children: content })] })] }));
};

export { AboutDialog };
