import { jsx, Fragment, jsxs } from 'react/jsx-runtime';
import { styled } from '@mui/material';
import { useState } from 'react';
import { Box } from '../Box.js';
import { InfoPanel } from '../InfoPanel.js';
import { Add12Regular } from '@fluentui/react-icons';
import { TextIconButton } from '../button/TextIconButton.js';
import { DetailsRow } from '../DetailsRow.js';
import '../../auth/config.js';
import '../../config.js';
import '../../auth/AuthProvider.js';
import '@azure/msal-react';
import { DisposalRequestStatus } from '../../types/requestTypes.js';
import '../../types/itemTypes.js';
import { formatDate } from '../../util/dateTime.js';
import 'react-redux';
import '@testing-library/react';
import '../../themes/light.theme.js';
import '../../store/store.js';
import 'redux-persist/integration/react';
import '../../store/slices/applicationSlice.js';
import '../../store/slices/downloadFileSlice.js';
import '../../store/slices/itemModalSlice.js';
import { PerspectiveClassChip } from '../chip/PerspectiveClassChip.js';
import { Chip } from '../chip/Chip.js';
import { ItemManagementStatusChip } from '../chip/ItemManagementStatusChip.js';
import { Typography } from '../Typography.js';
import { RetentionClassChip } from '../chip/RetentionClassChip.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '../../constants/messages.js';
import '../../services/requestApi.js';
import '../../services/userApi.js';
import { useSaveItemDetailsMutation } from '../../services/itemApi.js';
import '../../services/requestDetailsApi.js';
import '@pdftron/webviewer';
import 'lodash';
import './utils.js';
import 'react-router-dom';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../../store/thunks/downloadFileThunk.js';
import '@dnd-kit/utilities';
import '@mui/lab';
import '../dialog/components/item/BusinessTypeField.js';
import '@syncfusion/ej2-react-calendars';
import 'dayjs';
import '../dialog/components/item/CustomTypeField.js';
import '../dialog/components/item/PerspectiveClassField.js';
import { EditPerspectiveClassesDialog } from '../dialog/EditPerspectiveClassesDialog.js';
import { EditRetentionClassesDialog } from '../dialog/EditRetentionClassesDialog.js';
import { EditCategoriesDialog } from '../dialog/EditCategoriesDialog.js';
import '@mui/material/Breadcrumbs';
import '@mui/material/Menu';
import '@mui/material/MenuItem';
import { encImcUrl } from '../../constants/const.js';

const StyledImage = styled('img') ``;
var Dialog;
(function (Dialog) {
    Dialog["PERSPECTIVE"] = "perspective";
    Dialog["RETENTION"] = "retention";
    Dialog["CATEGORIES"] = "categories";
    Dialog["BUSINESS_TYPE"] = "businessType";
})(Dialog || (Dialog = {}));
const ItemGovernancePanel = ({ item, disposalRequest, hasEditPermission, onOpen, }) => {
    const [dialogProps, setDialogProps] = useState({
        open: false,
    });
    const [saveItemDetails, { isLoading }] = useSaveItemDetailsMutation();
    // Add and Edit variables for Perspective Classes
    const hasAddButtonPerspective = hasEditPermission &&
        !item?.BusinessClasses?.value?.length &&
        item?.Status !== 'Destroyed' &&
        disposalRequest?.Status !== DisposalRequestStatus.InProgressOrComplete;
    const hasEditButtonPerspective = hasEditPermission &&
        item?.BusinessClasses?.value?.length &&
        item?.Status !== 'Destroyed' &&
        disposalRequest?.Status !== DisposalRequestStatus.InProgressOrComplete;
    // Add and Edit variables for Retention Classes
    const hasAddButtonRetention = hasEditPermission &&
        !item?.Classifications?.value?.length &&
        item?.Status !== 'Destroyed' &&
        disposalRequest?.Status !== DisposalRequestStatus.InProgressOrComplete;
    const hasEditButtonRetention = hasEditPermission &&
        item?.Classifications?.value?.length &&
        item?.Status !== 'Destroyed' &&
        disposalRequest?.Status !== DisposalRequestStatus.InProgressOrComplete;
    // Add and Edit variables for Catagories
    const hasAddButtonCategories = hasEditPermission &&
        !item?.Categories?.value?.length &&
        item?.Status !== 'Destroyed' &&
        disposalRequest?.Status !== DisposalRequestStatus.InProgressOrComplete;
    const hasEditButtonCategories = hasEditPermission &&
        item?.Categories?.value?.length &&
        item?.Status !== 'Destroyed' &&
        disposalRequest?.Status !== DisposalRequestStatus.InProgressOrComplete;
    const setDialogDetails = ({ open, title, dialog, fieldLabel, propertyName, propertyValue, }) => setDialogProps({
        open,
        title,
        dialog,
        fieldLabel,
        propertyName,
        propertyValue,
    });
    const handleClose = () => {
        setDialogDetails({
            open: false,
        });
    };
    const collectionIconUrl = `${encImcUrl}BusinessClasses/Documents/document header footer.svg`;
    const handleSave = async (value, propertyName) => {
        // this is to prevent from calling the API again.
        if (isLoading)
            return;
        // Format the selected value(s) from the dialog.
        // Get only the needed IDs (ID and TypeDefId)
        const formattedDialogValues = value?.length
            ? value?.map(({ ID, TypeDefId }) => ({
                ID,
                TypeDefId,
            }))
            : null;
        // Format form details
        // propertyName is based on the selected dialog
        // BusinessClasses property = Perspective Classes Dialog
        // Classifications property = Retention Classes Dialog
        const formDetails = {
            [propertyName]: formattedDialogValues,
        };
        const { ID, TypeDefId } = item;
        // request object
        const requestObj = {
            ID,
            TypeDefId,
            formDetails,
        };
        try {
            const result = await saveItemDetails(requestObj);
            console.log('Item Details updated:', result);
        }
        catch (error) {
            // Handle error
            console.error('Error on updating the item details:', error);
        }
        setDialogDetails({ open: false });
    };
    return (jsx(Fragment, { children: Object.keys(item).length ? (jsxs(Fragment, { children: [jsxs(InfoPanel, { title: 'Governance Properties', inset: '3.5rem', style: {
                        paddingRight: '5.25rem',
                        paddingTop: '0.988rem',
                        paddingBottom: '3.25rem',
                    }, children: [jsx(DetailsRow, { label: 'Status', data: jsx(Box, { background: 'none', width: '100%', style: {
                                    maxWidth: 'fit-content',
                                }, children: jsx(ItemManagementStatusChip, { status: item?.Status, border: true, sx: { minHeight: '2.5rem' } }) }), alignData: true }, 'governance-status'), jsx(DetailsRow, { label: 'Date Created in EncompaaS', data: formatDate(item?.DateCreatedInEnc) }, 'governance-date-created-in-encompaas'), jsx(DetailsRow, { label: 'Member Of', data: jsx(Fragment, { children: jsx(Box, { direction: 'row', background: 'none', gap: 'small', style: {
                                        flexWrap: 'wrap',
                                        overflow: 'hidden',
                                        maxWidth: '100%',
                                    }, children: item?.MemberOf?.value?.map((memberOf, index) => (jsx(Chip, { title: memberOf?._Display, icon: memberOf?._ImageId ? (jsx(StyledImage, { src: collectionIconUrl, style: { height: '1.5rem' } })) : null, isLink: true, onClick: () => onOpen && onOpen(memberOf, 'modal'), sx: { minHeight: '2.5rem' } }, `memberOf-${index}`))) }) }) }, 'governance-member-of'), jsx(DetailsRow, { label: 'Perspective Classes', data: jsx(Box, { direction: 'row', background: 'none', gap: 'small', style: { flexWrap: 'wrap' }, children: hasAddButtonPerspective ? (jsx(Box, { background: 'none', direction: 'row', children: jsx(TextIconButton, { size: 'medium', color: 'primary', textVariant: 'body2', startIcon: jsx(Add12Regular, {}), onClick: () => setDialogDetails({
                                            open: true,
                                            dialog: Dialog.PERSPECTIVE,
                                            propertyName: 'BusinessClasses',
                                            title: 'Add Perspective Classes',
                                            fieldLabel: 'Perspective Classes',
                                            propertyValue: item?.BusinessClasses?.value,
                                        }), text: 'Add perspective classes' }) })) : (item?.BusinessClasses?.value?.map((businessClass, index) => (jsx(PerspectiveClassChip, { businessClass: businessClass }, `${businessClass?._Display}-${index}`)))) }), alignData: true, onEdit: hasEditButtonPerspective
                                ? () => setDialogDetails({
                                    open: true,
                                    dialog: Dialog.PERSPECTIVE,
                                    propertyName: 'BusinessClasses',
                                    title: 'Edit Perspective Classes',
                                    fieldLabel: 'Perspective Classes',
                                    propertyValue: item?.BusinessClasses?.value,
                                })
                                : null }, 'governance-perspective-classes'), jsx(DetailsRow, { label: 'Categories', data: jsx(Box, { direction: 'column', background: 'none', gap: 'small', style: { flexWrap: 'wrap' }, children: hasAddButtonCategories ? (jsx(Box, { background: 'none', direction: 'row', children: jsx(TextIconButton, { size: 'medium', color: 'primary', textVariant: 'body2', startIcon: jsx(Add12Regular, {}), onClick: () => setDialogDetails({
                                            open: true,
                                            dialog: Dialog.CATEGORIES,
                                            propertyName: 'Categories',
                                            title: 'Add Categories',
                                            fieldLabel: 'Categories',
                                            propertyValue: item?.Categories?.value,
                                        }), text: 'Add categories' }) })) : (item?.Categories?.value?.map((category, index) => (jsx(Typography, { variant: 'body2', children: category?.DisplayName ?? category?._Display }, `${category?.DisplayName}-${index}`)))) }), alignData: true, onEdit: hasEditButtonCategories
                                ? () => setDialogDetails({
                                    open: true,
                                    dialog: Dialog.CATEGORIES,
                                    propertyName: 'Categories',
                                    title: 'Edit Categories',
                                    fieldLabel: 'Categories',
                                    propertyValue: item?.Categories?.value,
                                })
                                : null }, 'governance-categories'), jsx(DetailsRow, { label: 'Retention Classes (all)', data: jsxs(Box, { direction: 'row', background: 'none', gap: 'small', style: {
                                    flexWrap: 'wrap',
                                    overflow: 'hidden',
                                    maxWidth: '100%',
                                }, children: [hasAddButtonRetention ? (jsx(Box, { background: 'none', direction: 'row', width: '100%', children: jsx(TextIconButton, { size: 'medium', color: 'primary', textVariant: 'body2', startIcon: jsx(Add12Regular, {}), onClick: () => setDialogDetails({
                                                open: true,
                                                dialog: Dialog.RETENTION,
                                                title: 'Add Retention Classes',
                                                fieldLabel: 'Retention Classes',
                                                propertyName: 'Classifications',
                                                propertyValue: item?.Classifications?.value,
                                            }), text: 'Add retention classes' }) })) : null, item?.ApplicableClassifications?.value?.map((classification, index) => (jsx(RetentionClassChip, { classification: classification, sx: { maxWidth: '100%' } }, `${classification?.DisplayName}-${index}`)))] }), alignData: true, onEdit: hasEditButtonRetention
                                ? () => setDialogDetails({
                                    open: true,
                                    dialog: Dialog.RETENTION,
                                    title: 'Edit Retention Classes',
                                    fieldLabel: 'Retention Classes',
                                    propertyName: 'Classifications',
                                    propertyValue: item?.Classifications?.value,
                                })
                                : null }, 'governance-rentention-classes')] }), dialogProps.dialog === Dialog.PERSPECTIVE && (jsx(EditPerspectiveClassesDialog, { onSave: handleSave, onClose: handleClose, open: dialogProps.open, title: dialogProps.title, fieldLabel: dialogProps.fieldLabel, propertyName: dialogProps.propertyName, propertyValue: dialogProps.propertyValue })), dialogProps.dialog === Dialog.RETENTION && (jsx(EditRetentionClassesDialog, { onSave: handleSave, onClose: handleClose, open: dialogProps.open, title: dialogProps.title, fieldLabel: dialogProps.fieldLabel, propertyName: dialogProps.propertyName, propertyValue: dialogProps.propertyValue })), dialogProps.dialog === Dialog.CATEGORIES && (jsx(EditCategoriesDialog, { onSave: handleSave, onClose: handleClose, open: dialogProps.open, title: dialogProps.title, fieldLabel: dialogProps.fieldLabel, propertyName: dialogProps.propertyName, propertyValue: dialogProps.propertyValue }))] })) : null }));
};

export { Dialog, ItemGovernancePanel };
